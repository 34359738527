
@media (min-width: 768px) {
.container {
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  min-height: 300px;
  /* background-color: #fff; */
}

.lead-manage-body-wrapper {
  width: 100%;
  min-height: 70vh;
  margin-bottom: 20px;
}


.lead-manage-body-container {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.lead-manage-body-container-leadmanage
{
  margin: 0px 40px 40px 40px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.lead-Container {
  position: relative;
  /* padding-bottom: 60px; Add padding to ensure the button is not overlaid */
  /* min-height: 100vh; Ensure the container takes full height */
  /* display: flex;
  flex-direction: column; */
}
.button-container{
  margin-top: 40px;
}

.lead-Container .button-container {
  background-color: white;
  padding: 10px 0;
  width: 100%;
  position: absolute;
  bottom: 20;
  /* box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2); Add box shadow */
  display: flex;
  justify-content: center; /* Center the button horizontally */
}

.lead-Container .button-container .btn-primary {
  width: 100%;
  background-color: #004ad9;
  font-size: 16px;
  border: none;
  height: 40px;
  color: white;
}


.form-container{
  display: flex;
  flex-direction: row;
  gap: 40px;
  width: 100%;
  margin-top: 15px;
}

.form-mobile-container{
  margin-top: 80px;
}

.input-container{
  width: 100%;
}
.anr-drawer .ant-drawer-body{
  padding: 0px;
}

}

@media (max-width: 768px) {

  .input-container{
    width: 100%;
    margin-top: 15px;
  }

  .lead-Container .button-container .btn-primary {
    width: 100%;
    background-color: #004ad9;
    font-size: 16px;
    border: none;
    height: 40px;
    color: white;
  }


}
.lead-full-page-button {
  padding: 10px;
  background-color: '#004ad9';
  border-radius: 4px;
  color: white;
  margin-right: 80px;
  text-decoration: none;
}