.footer{
    justify-content: space-between;
    padding: 50px 80px 25px 80px;
    background-color: #f8f8f8;
    border-top: 1px solid #e7e7e7;
    display: 'flex';
    flex-direction: column; 
}
.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
}
.section1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 40%;
}
.company_details,.information, .partner_with_us {
    margin: 0 10px;
    padding-bottom: 30px;
}
.follow_us{
    flex: 0;
    margin: 0 10px;
    padding-bottom: 30px;
}

.link-heading{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
}
.ul-style{
    list-style: none;
    padding-top: 15;
    padding: 0px;
}
.list-a{
    color: #6e6e6e;
    cursor: pointer;
}
.list-a :hover{
    text-decoration: none;
    color: #000;
    font-weight: 500;
}
.li-style{
    margin-bottom: 10px;
    font-size: 14px;
}
.li-style :hover{
    text-decoration: none;
    color: #000;
    font-weight: 500;
}
.appLinks {
    display: flex;
    gap: 10px;
    padding-top: 30px;
    size: 200;
  }
  .socialLinks {
     display: flex;
    padding-right: 5px;
    gap: 10px;
  }
  .copy_right {
    flex: 1 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #999;
  }
  .row{
    display: flex;
    justify-content: space-between;
  }
.li-style1{
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    list-style-type: none;
}
.link-heading1{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    display: block;
}
.container-of-links{
  padding: 0px;
}
.link-head{
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 30px;
}
.link-main-container{
    padding: 50px 50px 50px 70px;
}
.mobile-only {
    display: none;
}
.hr{
    display: none;
}
@media (max-width: 768px) {
    .footer {
        padding: 30px ;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }
    .section1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: auto;
    }

    .information, .company_details, .partner_with_us, .follow_us {
        width: 100%;
        margin: 0;
        padding-bottom: 20px;
        font-size: 12px; 
        align-items: left;
    }
    .link-heading{
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .ul-style{
        padding: 0;
        font-size: small;
    }
    
    .li-style{
        margin-bottom: 10px;
        font-size: 12px;
    }
    
    .appLinks {
        flex-direction: row;
        gap: 10px;
    }

    .socialLinks {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
        float: left;
        padding-bottom: 5px;
    }

    .copy_right {
        text-align: center;
        padding-top: 0px;
        font-size: 10px; 
    }
    .row{
        flex-direction: column;
    }
    .link-heading1{
        font-size: 14px;
        margin-bottom: 20px;
        font-weight: 600;
        display: block;
    }
    .li-style1{
        margin-bottom: 15px;
        font-size: 12px;
    }
    .link-head{
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 20px;
    }
    .link-main-container{
        padding: 10px;
        padding-bottom: 0px;
        padding-left: 30px;
    }
    .container-of-links{
        padding-top: 10px;
    }
    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: block;
    }
    .hr{
        display: block;
    }
    .vm-footer-dropdown-icon {
        font-size: 0px;
        margin-right: 5px;
    }
}
