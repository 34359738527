.profileHeader {
    margin-top: 10px;
}

.drawerHeader {
    font-size: 18px;
    font-weight: 500;
}
.contentRow {
    margin-bottom: 10px;
    display: flex;
    gap: 30px;
}

.contentKey {
    font-size: 14px;
    color: #1A1A1A;
    width: 50px;
}

.contentValue {
    color: #9c9c9c;
}
