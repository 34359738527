.tableCellCenter {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1px;
}
.dateColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.drawerContent {
    width: 80%;
    margin-bottom: 30px;
}
.labelMarginRight {
    margin-right: 30px;
}
.datePicker {
    padding: 7px 8px 6px 8px;
    border: 1px solid #B0B0B0;
}
.radioGroup {
    width: 60%;
    margin-bottom: 30px;
}
.segmentContainer {
    margin-top: 15px;
}
.segment {
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}
.segmentButton {
    width: 140px;
    background-color: #2A52BB;
}
.tableContainer {
    position: relative;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 50%;
    overflow: scroll;
}
.emptyVisitImage {
    margin-top: 15px;
}
.emptyVisitText {
    margin-top: 20px;
    color: #737373
}
visitFormButton {
    width: 100px;
    background-color: #2A52BB;
}
