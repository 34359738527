a{
    color: black;
}


b { 
    margin-right: 3px;
  }
  
      .calendar-app {
        display: flex;
        min-height: 100%;
        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
        font-size: 14px;
      }
  
  .calendar-main {
    flex-grow: 1;
    padding: 1em 3em;
  }
  
  .fc { 
    max-width: 1100px;
    margin: 0 auto;
    
  }

  .event-main {
    display: flex;
    gap: 4px;
    flex-direction: row;
    height: 100%;
  }

.calendar-header{
  max-width: 1100px;
   margin: 0 auto;
  border :1px solid rgb(235, 237, 240);
  display:flex ;
  justify-content:space-between;
  margin-bottom:12px ; 
  padding:12px ;
  }