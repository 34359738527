
  
   /* .button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    gap: 30px;
    padding: 10px;
    margin-left: -20px;
    bottom: 0;
    box-shadow: 0px 5px 5px #000, 0px -5px 5px lightgrey;
} */
.next-btn{
  width: 100%;
  height: 50px;
  border-radius: 4px;

  border: none;
  }

  .btn-text{
    color: #fff;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.container
{
  height: 100%;
}

/* .standalone-Container{
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 150px;
  position: relative;
} */

/* .standalone-header
{
  font-size: 20px;
  font-weight: 600;
  color: #000;
} */


@media (max-width: 1306px) {
  .stanalone-feedback-image {
    min-height: max-content !important;
    width: 100%;
  }
}


@media (min-width: 768px) {
  .stanalone-feedback-image{
    min-height: 100%;
  }
  .stanalone-form-style{
    width: 50%;
  }

  .standalonetype-container
  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .card-text
  {
    font-size: 22px;
    color: #000;
    margin-top: 10px;
  }

.Card-Container{
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

  .cardcontainer
  {
  display: flex;
  justify-content: center;
  gap: 80px;
  margin-top: 30px;
  }
  .desktop-view{
    display: flex;
    justify-content: center;
    align-items: center;
  }
.desktop-divider{
  height: calc(100vh - 85px);
  display: flex;
}
  .Container {
      border: 2px solid #2a52bb;
      width: 230px; 
      height: 250px;
      box-sizing: border-box;
      min-width: 180px;
      cursor: pointer;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      padding: 10px;

    }
    .icon {
      width: 35px;
      padding: 4px;
      border-top-right-radius: 16px;
      margin-left: 88%;
      margin-top: 1px;
      position: absolute;
      right: 0;
      top: 0;
    }
    
    .icon-style {
     width: 130px  ;
    }
    
    .style {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-top: 10px;
      flex-grow: 1; 
      display: flex;
      justify-content: center;
      align-items: center;
    }

 .desktop-button{
  display: flex;
  justify-content: center;
 }   
     .button-container{
      margin-top: 60px;
      /* width: 55%; */
  }
  .next-btn{
    height: 40px;
    border-radius: 4px;
    border: 1px solid#2a52bb;
    width: 300px;
    }
  
    .btn-text{
      margin-left: 10px;
      font-size: 18px;
      font-weight: 500;
  }
  
  .standalone-header
  {
    font-size: 28px;
    font-weight: 500;
    color: #2a52bb;
    display: flex;
    justify-content: center;

  }
  .SearchCity-Container
  {
    height: 100%;
    background-color: 'white';
  }
  .selectCity-heading{
    font-size: 28px;
    font-weight: 500;
    color: #000;
    margin-bottom: 20px;
  }
  .Search-container{
   display: flex;
   justify-content: center;
   
  }
  .search-input
  {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-top: 10px;
 
  }
  .vendor-text{
    font-size: 28px;
    color: #000;
    font-weight: 500;
  }
  .dropdown{
    border-radius: 4px;
    margin-bottom: 60px;
    margin-top: 20px;
  }
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    margin-top: 5px;
}


   .search-Icon{
    position: absolute;
    align-self: flex-end;
    margin-top: 25px;
    right: 10px;
  }
  

   .venues
   {
    margin-top: 10px;
    height: 'auto';
    background-color: 'red';
    overflow-x: auto;
    overflow: hidden;
   }
   .button-Container
   {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    width: 100px
   }
   .button{
    width: 100%;
    height: 45px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid lightgray;
}
.button-next{
  width: 100%;
  height: 45px;
  border-radius: 4px;
  background-color: lightgray;
  border: 1px solid lightgray;
}
.text
{
  font-size: 18px;
  color: #6F6F6F;
  font-weight: 500;
}
.Button{
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.button-next-active{
  background-color: #2a52bb;

}
.active-text{
  color: 'white';
}
.desktop-Container{
  background-color: #f6ecff;
}
.success-Container{
background-color: white;
width: auto;
height: auto;
padding: 20px;
padding-top: 30px;
border-radius: 10px;
}
.success-text{
  display: flex;
  justify-content: center;
}
.Sucessful-text
{
  font-size: 30px;
  font-weight: 600;
  margin-top: 30px;
  color: #5CA829;
}
.bottom-text
{
  font-size: 26px;
  font-weight: 500;
  margin-top: 30px;
  color: black;
}
.vendorcardContainer{
  display: grid;
  grid-template-columns: auto auto;
  list-style: none;
  padding: 0;
  justify-content: space-between;
  row-gap: 40px;
  margin-top: 50px;
  max-height: 100%;
}
.desktop-container
{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.active-text
{
  color: white;
}

.dropdown-container
{
  overflow-y: auto;
  max-height: 200px;
  margin-top: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
}
.feedback-heading{
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.feedback-subheading{
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;

}
.feedback-image{
  width: 450px;
}
.padding-container
{
  margin-left: 50px;
  margin-right: 50px;
}
.iconstyle {
  display: flex;
  justify-content: flex-end;
  padding: -8px;
 }
 .standalone-vendor-header
 {
  font-size: 28px;
  font-weight: 500;
  color: #000;
 }
 .back-btn{
  text-decoration: none;
  border: 1px solid #0057ff;
  border-radius: 3px;
  padding: 5px 15px;
 }
 .back-btn:hover{
 background-color: #0057ff;
 color: #fff;
 text-decoration: none;
 }


}

@media (max-width: 768px) {

.desktop-container{

  padding:0% 5%;
  height: calc(100vh - 120px);
  position: relative;
  margin-top: 120px;

}


  .dropdown-container
  {
    overflow-y: auto;
    max-height: 250px;
    margin-top: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
  }

.Card-Container
{
  height: 100vh;
  margin-top: 130px;
  margin-left: 20px;
  margin-right: 20px;
}
.vendor-text{
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

  .venues
  {
   margin-top: 10px;
   height: 300px;
   overflow-y: auto;
   overflow: hidden;
  }
  .cardcontainer
  {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
  .Container {
      border: 1px solid #592b7e;
      width: 165px;
      height: 165px;
      border-radius: 8px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    
    .iconstyle {
     display: flex;
     justify-content: flex-end;
    }
    
    
    .icon {
      font-size: 12px;
      padding: 3px;
      border-top-right-radius: 8px;
      position: absolute;
      right: 0;
      top: 0;
    }
    
    .icon-style {
      width: 80px;
    }
    
    .style {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      flex-grow: 1; 
      display: flex;
      justify-content: center;
      align-items: center;
    }

  
    .btn-text{
      color: #fff;
      margin-left: 10px;
      font-size: 18px;
      font-weight: 500;
  }

  
  .standalone-header
  {
    font-size: 20px;
    font-weight: 600;
    color: #000;
  }
  .search-input
  {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    margin-top: 20px;
  }
  .search-icon{
   position: absolute;
   right: 10px; 
   margin-top: 30px;
  }
  .search-Icon{
    position: absolute;
    right: 30px; 
    margin-top: 30px;
  }
  .vendorcardContainer{
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    row-gap: 20px;
}
.SearchCity-Container
{
  height: 600px;
  width: 100%;
  background-color: white;
  /* margin-top: 50px; */
  position: relative;
}
.selectCity-heading{
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 50px;
}
.button{
    width: 30%;
    height: 45px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid lightgray;
}
.button-next{
  width: 60%;
  height: 45px;
  border-radius: 4px;
  background-color: lightgray;
  border: 1px solid lightgray;
}
.text
{
  font-size: 16px;
  color: #1A1A1A;

}
.button-Container{
  display: flex;
  position:fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  padding: 10px; 
  background-color: #ffffff; 
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); 
}
.button-next-active{
  background-color: #592b7e;

}
.active-text{
  color: white;
}
.standalone-Container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 120px;
}

.button-container {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 10px; 
  background-color: #ffffff; 
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); 
}

.next-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}
.dropdown{
  margin-top: 20px;
  margin-bottom: 50px;
}
.feedback-container{
  margin-top: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.feedback-subheading{
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 40px;

}
.feedback-heading{
  font-size: 32px;
  font-weight: 500;
  line-height: 35px;
  display: flex;
  justify-content: center; 
}
.feedback-image{
  width: 350px;
}
.padding-container
{
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.standalone-vendor-header
{
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
}